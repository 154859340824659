<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <el-button class="button" icon="el-icon-plus" type="primary" style="margin-left: 0"
        @click="handleAddCallforPay">批量制作对账单</el-button>
      <!-- <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 10px"
        @click="handleAddSend"
        >批量发送</el-button
      > -->
      <el-button class="button1" type="text" @click="EditEmail">
        <span>本月欠款:￥ {{ toThousands(entity.MoonSincere) }}</span>
        <span style="margin-left: 40px">全部欠款: ￥ {{ toThousands(entity.OwnSincere) }}</span>
      </el-button>
      <div class="searchParams">
        <!-- 公司组件 -->
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input class="input" v-model="queryParam.CustomerName" placeholder="请输入客户名称进行查询" clearable
          @keydown.enter.native="getDataList"></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table ref="multipleTable" :data="tableData" style="width: 100%" :max-height="height" v-loading="loading"
        @selection-change="handleSelectionChange">
        <el-table-column align="center" :show-overflow-tooltip="true" fixed type="selection" :selectable="selectEnable"
          width="55"></el-table-column>
        <el-table-column prop="cCusName" width="250px" label="公司名称">
          <template slot-scope="scope">
            <span @click="rowClickHandler(scope.row)" style="
                color: #1890ff;
                border-bottom: 1px solid #1890ff;
                cursor: pointer;
              ">{{ scope.row.cCusName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="PendingSum" label="欠款余额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.PendingSum - scope.row.CollectionSum) }}
          </template>
        </el-table-column>
        <el-table-column prop="cCusPhone" width="200" label="联系方式">
        </el-table-column>
        <el-table-column prop="QIANMoney" label="最近欠款/日期">
          <template slot-scope="scope">
            {{
            toThousands(scope.row.QIANMoney) +
            " / " +
            scope.row.QIANTIME.substring(0, 10)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="SHOUMoney" label="最近收款/日期">
          <template slot-scope="scope">
            <span v-if="scope.row.SHOUTIME == null">
              {{ toThousands(scope.row.SHOUMoney) }}</span>
            <span v-else>
              {{
              toThousands(scope.row.SHOUMoney) +
              " / " +
              scope.row.SHOUTIME.substring(0, 10)
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="BillDate" label="最近对账日期">
          <template slot-scope="scope">
            {{ scope.row.BillDate?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="Isitover" width="68px" label="状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.Isitover > 0">已逾期</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click.stop="handleShare(scope.row)">对账</el-button>
            <!-- <el-button
              type="text"
              @click="handleCallForPay(scope.row)"
              >催款</el-button
            > -->
            <!-- <el-button type="text" @click="handleRuningBook(scope.row)"
              >流水账</el-button
            > -->
            <!-- <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <el-dialog title="客户邮箱" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="Email" placeholder="请输入客户邮箱"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="dialogLoading" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="请输入时间范围" :visible.sync="dialogVisible" width="300px">
      <div>
        <!-- <el-date-picker v-model="range" type="daterange" style="width: 250px; margin-left: 10px" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="changeTime">
        </el-date-picker> -->
        <el-date-picker v-model="singleRange" type="date" placeholder="截止日期"  value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="dialogLoading" @click="handleDateTimeChange">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <Email ref="Email" />-->
    <!-- 对账 -->
    <ReconArrearList ref="ReconArrearList" :parentObj="{ ...this }" />
    <!-- 催款 -->
    <CallForPay ref="CallForPay" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import ReconArrearList from "./ReconArrearList.vue";
import CallForPay from "./CallForPay.vue";
import { toThousands } from "../../utils/Tools";
// import Email from "./Email.vue";
export default {
  props: {},
  components: {
    ReconArrearList,
    CallForPay,
    radioGroup,
    // Email,
  },
  mounted() { },
  created() { },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      // 时间范围
      singleRange:'',
      range: [],
      dialogVisible: false,
      // 表格列表多选的选好数据
      multipleSelection: [],
      // 千分位格式化
      toThousands,
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      // 欠款列表数据
      tableData: [],
      fileList: [],
      queryParam: { CompanyId: "全部" },
      loading: false,
      dialogVisible: false,
      Email: "",
      row: {},
      dialogLoading: false,
      entity: {},
    };
  },
  methods: {
    // 表格行点击
    rowClickHandler(row, i, v) {
      // console.log('row', row, i , v)
      let obj = {
        Id: row.Id,
        EndTime: row.BillDate ? row.BillDate.substring(0, 10) : "",
        StartTime: "",
      };

      this.$refs.CallForPay.openForm("流水账", obj);
    },
    // 多选框选中限制条件
    selectEnable(row, rowIndex) {
      if (row.PendingSum - row.CollectionSum == 0) {
        return false; //不可选择
      } else {
        return true;
      }
    },
    // 时间范围选择
    changeTime(e) {
      console.log("时间", this.range);
    },
    // 表格列表多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量制作对账单
    handleAddCallforPay() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请先选择一条数据");
        return;
      }
      this.range = [];
      // 时间弹窗
      this.dialogVisible = true;
    },
    // 批量发送
    // handleAddSend() {
    //   this.$message.warning("待开发");
    // },

    // 时间范围选择确认
    handleDateTimeChange() {
      // if (this.range.length == 0) return this.$message.error("请选择时间范围");
      if (this.singlerange == '') return this.$message.error("请选择截止日期");
      let arr = [];
      this.multipleSelection.forEach((item) => {
        arr.push(item.Id);
      });
      // 批量制作对账单
      let obj = {
        CompanyId: this.queryParam.CompanyId,
        CustomerIds: arr,
        StartTime: '2000-01-01',
        EndTime: this.singlerange,
      };

      this.$http
        .post("/BO/BIllcollection/SaveBillConfirmList", obj)
        .then((res) => {
          this.dialogLoading = false;
          if (res.Success) {
            this.dialogVisible = false;
            this.$message.success("对账单创建成功");
            // 清空选择的数据
            this.multipleSelection = [];
            this.tableData = [];
            this.getDataList();
          } else {
            this.$message.error(res.Msg);
          }
        });
    },

    // 配置邮箱
    EditEmail() {
      if (this.queryParam.SupplieId.split(",").length > 1)
        return this.$message.error("请选择公司");

      this.$refs.Email.openDialog(this.queryParam.SupplieId);
    },
    // 发送邮箱
    handleEmail(row) {
      this.row = row;
      this.dialogVisible = true;
    },
    // 邮箱确认
    handleClose() {
      if (!this.Email) return this.$message.error("请输入邮箱");
      this.dialogLoading = true;
      this.$http
        .post("/Base/Base_Reconciliation/SendMail", {
          Id: this.row.Id,
          CompanyId: this.row.Company,
          RecipientEmail: this.Email,
          CcusName: this.row.CustomerName,
          CodeDiv: this.row.LetterCode
            ? `<div>对账函密码:${this.row.LetterCode}</div>`
            : "<div></div>",
          Url:
            "https://hzq.yoojet.com/#/SendSMSMobileCopy?prop=" + this.row.Id,
        })
        .then((res) => {
          this.dialogLoading = false;
          if (res.Success) {
            this.dialogVisible = false;
            this.$message.success("发送成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.CompanyId = e;
        this.getDataList();
        // 获取今日新欠 全部欠款金额
        this.getHeaderData();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
      // 获取今日新欠 全部欠款金额
      this.getHeaderData();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        return this.$message.error("请选择公司");
      } else {
        this.$http
          .post(
            "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
            this.queryParam.SupplieId,
            {}
          )
          .then((res) => {
            if (res.Success) {
              this.$refs.editForm.openForm(
                "新增对账函",
                res.Data.Content,
                this.queryParam.SupplieId
              );
            } else {
              this.$message.error(res.Msg);
            }
          });
      }
    },
    // 对账点击事件
    handleShare(row) {
      this.$refs.ReconArrearList.openForm(row.Id, row.CompanyId);
    },
    // 催款点击事件
    handleCallForPay(row) {
      let obj = {
        Id: row.Id,
        EndTime: row.BillDate.substring(0, 10),
        StartTime: "2020-1-1",
      };
      this.$refs.CallForPay.openForm("催款", obj);
    },
    // 流水账点击事件
    handleRuningBook(row) {
      let obj = {
        Id: row.Id,
        EndTime: row.BillDate.substring(0, 10),
        StartTime: "",
      };

      this.$refs.CallForPay.openForm("流水账", obj);
    },

    handleView(row) {
      let url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      window.open(url, "_blank");
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },

    // 获取欠款列表数据
    getDataList() {
      this.loading = true;
      this.$http
        // .post("/BO/CustomerInfo/GetCustomerInfoPc", {
        .post("/BO/CustomerInfo/GetCustomerInfoList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            console.log("获取结果", this.tableData);
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
    getHeaderData() {
      this.$http
        .post("/BO/BIlling/GetOwingStatistics", {
          Search: {
            CommpayId: this.queryParam.CompanyId,
          },
        })
        .then((res) => {
          if (!res.Success) return this.$api.msg(res.Msg);
          this.entity = res.Data;
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.del {
  color: red;
}

.button{
  background-color: #d50700;
  border: #d50700;
}

.button:hover {
  background-color: #d50700;
}

.button1 {
color: #d50700;
}
</style>
